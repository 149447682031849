import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import NavItem from './navItem';
import { State } from 'shared/interface';
import { createAction } from 'shared/util/utility';
import * as actionTypes from 'store/action-types';
import { checkPermission } from 'shared/util/permission';
import httpService from 'shared/services/http.service';
import { API_CONFIG, allPermissionMapping } from 'shared/constants/constants';
import { filter } from 'lodash';
import { SideBarMenu, UserProfileResponse } from 'features/login/interface/login.interface';
import localizationConstants from 'shared/util/translation/constants';
import authService from 'shared/services/auth.service';
import { refreshLanguage } from 'shared/util/localization';

const SideNav: React.FC = () => {

	const [openStatus, setOpenStatus] = useState<{ [key: string]: boolean }>({});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const auth = useSelector((state: State) => {
		return state.auth;
	})

	const toggleIsOpen = (name: string) => {
		if (openStatus[name]) {
			setOpenStatus({})
		} else {
			setOpenStatus({ [name]: true })
		}
	}

	const hideSidebar = () => {
		if (window.innerWidth < 768) {
			document.body && document.body.classList.remove('mini-navbar');
		}
	}

	const goToProfile = () => {
		hideSidebar();
		navigate('/profile');
	};

	const renderSideNav = () => dispatch(createAction(actionTypes.NO_ACTION));

	const getSideNavMenu = (profileResponse: UserProfileResponse) => {
		const sidebarMenu: SideBarMenu[] = [];
		const permissions = Object.keys(profileResponse.user.permissions);
		const sidebarItems = [
			localizationConstants.dashboardTitle,
			localizationConstants.organizationsTitle,
			localizationConstants.accounting,
			// localizationConstants.invoices,
			localizationConstants.posbillAdmin,
			localizationConstants.users,
			localizationConstants.role,
			localizationConstants.phoenixAdmin,
			localizationConstants.jumpbirdAdmin,
			localizationConstants.resigoAdmin,
			localizationConstants.birdhubAdmin,
			localizationConstants.tse,
			localizationConstants.tools,
			localizationConstants.offerTool,
			localizationConstants.fiskaly
		];
		sidebarItems.forEach(sidebarItem => {
			const allPermissions = filter(permissions, p => {
				//search for permissions which contain either of ['list','create']
				return p.indexOf(`${sidebarItem}.`) === 0
			}).map(permission => {
				//users.create => 'create'
				return permission.split('.')[1]
			});
			let children;
			let name = sidebarItem;
			let route = sidebarItem;
			switch (name) {
				case localizationConstants.dashboardTitle:
					route = '';
					break;
				case localizationConstants.birdhubAdmin:
					children = [];
					route = 'birdhub';
					name = localizationConstants.birdhubAdminTitle;
					// if (permissions.includes(allPermissionMapping['birdhubCredentialList'])) {
					// 	children.push({ name: localizationConstants.credentials, url: '/birdhub/credential/list' });
					// }
					if (permissions.includes(allPermissionMapping['birdhubClientsList'])) {
						children.push({ name: localizationConstants.clients, url: '/birdhub/clients/list' });
					}
					if (!children.length) {
						return;
					}
					break;
				case localizationConstants.phoenixAdmin:
					children = [];
					route = 'phoenix';
					name = localizationConstants.phoenixAdminTitle;
					if (permissions.includes(allPermissionMapping['phoenixCompanyList'])) {
						children.push({ name: localizationConstants.companyList, url: '/phoenix/company/list' });
					}
					if (permissions.includes(allPermissionMapping['phoenixModuleList'])) {
						children.push({ name: localizationConstants.module, url: '/phoenix/module/list' });
					}
					if (permissions.includes(allPermissionMapping['phoenixPackageList'])) {
						children.push({ name: localizationConstants.package, url: '/phoenix/package/list' });
					}
					if (permissions.includes(allPermissionMapping['phoenixApiKeyList'])) {
						children.push({ name: localizationConstants.apiKeyList, url: '/phoenix/api-key/list' });
					}
					if (!children.length) {
						return;
					}
					break;
				case localizationConstants.jumpbirdAdmin:
					children = [];
					route = 'jumpbird';
					name = localizationConstants.jumpbirdAdminTitle;
					if (permissions.includes(allPermissionMapping['jumpbirdCompaniesList'])) {
						children.push({ name: localizationConstants.companies, url: '/jumpbird/company/list' });
					}
					// if (permissions.includes(allPermissionMapping['jumpbirdModuleList'])) {
					// 	children.push({ name: localizationConstants.module, url: '/jumpbird/module/list' });
					// }
					if (permissions.includes(allPermissionMapping['jumpbirdSubscriptionsListPackages'])) {
						children.push({ name: localizationConstants.packages, url: '/jumpbird/package/list' });
					}
					// if (permissions.includes(allPermissionMapping['jumpbirdApiKeyList'])) {
					// 	children.push({ name: localizationConstants.apiKeyList, url: '/jumpbird/api-key/list' });
					// }
					if (!children.length) {
						return;
					}
					break;
				case localizationConstants.resigoAdmin:
					children = [];
					route = 'resigo';
					name = localizationConstants.resigoAdminTitle;
					if (permissions.includes(allPermissionMapping['resigoAdminListMachines'])) {
						children.push({ name: localizationConstants.machineList, url: '/resigo/machine/list' });
					}
					if (permissions.includes(allPermissionMapping['resigoAdminListLicenses'])) {
						children.push({ name: localizationConstants.licenseList, url: '/resigo/licenses/list' })
					}
					if (permissions.includes(allPermissionMapping['resigoAdminDecodeLicenseDetail'])) {
						children.push({ name: localizationConstants.decodeLicense, url: '/resigo/licenses/decoder' })
					}
					// if (permissions.includes(allPermissionMapping['jumpbirdSubscriptionsListPackages'])) {
					// 	children.push({ name: localizationConstants.packages, url: '/jumpbird/package/list' });
					// }
					if (!children.length) {
						return;
					}
					break;
				case localizationConstants.organizationsTitle:
					children = [];
					route = 'organizations';
					if (permissions.includes(allPermissionMapping['organizationsSuperAdminModule'])) {
						children.push({ name: localizationConstants.superAdmin, url: '/organizations/superAdmin/list' });
					}
					if (permissions.includes(allPermissionMapping['organizationsLicenseModule'])) {
						children.push({ name: localizationConstants.license, url: '/organizations/license/list' });
					}
					if (permissions.includes(allPermissionMapping['organizationsSupportModule'])) {
						children.push({ name: localizationConstants.support, url: '/organizations/support/list' });
					}
					if (permissions.includes(allPermissionMapping['organizationsIncomingLicenseModule'])) {
						children.push({ name: localizationConstants.incomingLicenseList, url: '/organizations/incoming-license' });
					}
					if (permissions.includes(allPermissionMapping['phoenixIncomingLicenseModule'])) {
						children.push({ name: localizationConstants.phoenixIncomingLicenseList, url: '/organizations/phoenix/incoming-license' });
					}
					if (permissions.includes(allPermissionMapping['jumpbirdIncomingLicenseModule'])) {
						children.push({ name: localizationConstants.jumpbirdIncomingLicenseList, url: '/organizations/jumpbird/incoming-license' });
					}
					if (!children.length) {
						return;
					}
					break;
				case localizationConstants.accounting:
					children = [];
					route = 'accounting';
					if (permissions.includes(allPermissionMapping['allPartnerReportList'])) {
						children.push({ name: localizationConstants.provision, url: '/accounting/provision/reports/list' });
						// route = 'provision/reports/list';
					}
					if (permissions.includes(allPermissionMapping['invoicesList'])) {
						children.push({ name: localizationConstants.invoices, url: '/accounting/invoices/list' });
						// route = 'invoices/list';
					}
					if (permissions.includes(allPermissionMapping['inventories'])) {
						children.push({ name: localizationConstants.inventories, url: '/accounting/inventories/list' });
						// route = 'invoices/list';
					}
					if (permissions.includes(allPermissionMapping['listInventoryGroup'])) {
						children.push({ name: localizationConstants.inventoryGroup, url: '/accounting/inventory-group/list' });
						// route = 'invoices/list';
					}
					if (permissions.includes(allPermissionMapping['taxRulesListTaxRules'])) {
						children.push({ name: localizationConstants.taxRules, url: '/accounting/tax-rules/list' });
						// route = 'invoices/list';
					}
					if (permissions.includes(allPermissionMapping['invoicesCustomersList'])) {
						children.push({ name: localizationConstants.documents, url: '/accounting/customers/list' });
						// route = 'invoices/list';
					}
					if (permissions.includes(allPermissionMapping['reaList'])) {
						children.push({ name: localizationConstants.reaList, url: '/accounting/rea/list' });
					}
					if (permissions.includes(allPermissionMapping['turnoverStatistics'])) {
						children.push({ name: localizationConstants.turnoverStatistics, url: '/accounting/turnover' });
					}
					if (!children.length) {
						return;
					}
					break;
				case localizationConstants.posbillAdmin:
					children = [];
					route = 'licenses';
					if (permissions.includes(allPermissionMapping['licensesIncoming'])) {
						children.push({ name: localizationConstants.incomingLicenseList, url: '/licenses/incoming' })
					}
					if (permissions.includes(allPermissionMapping['licensesMachineList'])) {
						children.push({ name: localizationConstants.machineListTitle, url: '/licenses/machine/list' })
					}
					if (permissions.includes(allPermissionMapping['autoUpdateMachineList'])) {
						children.push({ name: localizationConstants.autoUpdateMachineTitle, url: '/licenses/auto-update-machine/list' })
					}
					if (permissions.includes(allPermissionMapping['licensesLicenseNumbers'])) {
						children.push({ name: localizationConstants.licenseNumberView, url: '/licenses/license-numbers' })
					}
					if (permissions.includes(allPermissionMapping['customerLicenseModuleList'])) {
						children.push({ name: localizationConstants.licenseModule, url: '/licenses/module/list' });
					}
					if (permissions.includes(allPermissionMapping['orderCodeExtensionsList'])) {
						children.push({ name: localizationConstants.orderCodeExtensions, url: '/licenses/order-code-extension' });
					}
					if (permissions.includes(allPermissionMapping['licensesConfigurationList'])) {
						children.push({ name: localizationConstants.configurations, url: '/licenses/configurations' });
					}
					if (permissions.includes(allPermissionMapping['licenseModuleGetLicenseNumberDetail'])) {
						children.push({ name: localizationConstants.licenseDecoder, url: '/licenses/license-decoder' });
					}
					if (!children.length) {
						return;
					}
					break;
				case localizationConstants.users:
					route = 'users';
					if (!permissions.includes(allPermissionMapping['usersModule'])) {
						return;
					}
					children = [{ name: localizationConstants.list, url: '/users/list' }];
					//allPermissionMapping contains our custom strings which map permissions string from server
					//here we retrive permission string on server using our mapping and check if it is present in 
					//users permission.
					//If yes then user will see respective menu 
					if (permissions.includes(allPermissionMapping['usersCreate'])) {
						children.push({ name: localizationConstants.create, url: '/users/add' })
					}
					break;
				case localizationConstants.role:
					route = 'roles';
					const roleModulePermission = permissions.includes(allPermissionMapping['rolesModule']);
					const permissionModulePermission = permissions.includes(allPermissionMapping['permissionsModule']);
					if (!roleModulePermission && !permissionModulePermission) {
						return;
					}
					name = localizationConstants.rolesAndPermissions;
					children = [
						{ name: localizationConstants.roles, url: '/roles/list' },
						{ name: localizationConstants.permissions, url: '/roles/permissions' }
					];
					if (!roleModulePermission) {
						name = localizationConstants.permissions;
						children = [{ name: localizationConstants.permissions, url: '/roles/permissions' }];
					}
					if (!permissionModulePermission) {
						name = localizationConstants.roles;
						children = [{ name: localizationConstants.list, url: '/roles/list' }];
					}
					break;
				case localizationConstants.tse:
					children = [];
					route = 'tse';
					if (permissions.includes(allPermissionMapping['tseCustomerList'])) {
						children.push({ name: localizationConstants.dashboardTitle, url: '/tse/dashboard' });
					}
					if (permissions.includes(allPermissionMapping['tseDeviceList'])) {
						children.push({ name: localizationConstants.tseSerialUpload, url: '/tse/device/list' });
					}
					if (permissions.includes(allPermissionMapping['tseCustomerList'])) {
						children.push({ name: localizationConstants.universeCustomers, url: '/tse/universe/customer/list' });
					}
					if (permissions.includes(allPermissionMapping['tseCustomerListV2'])) {
						children.push({ name: localizationConstants.tseCustomers, url: '/tse/customer/list' });
					}
					if (!children.length) {
						return;
					}
					break;
				case localizationConstants.tools:
					children = [];
					route = 'tools';
					if (permissions.includes(allPermissionMapping['zmListGetRequests'])) {
						children.push({ name: localizationConstants.zmList, url: '/tools/zm/list' });
					}
					if (checkPermission({ anyOnePermission: ['pbpwGetCurrentAndNextHourPasswords', 'pbpwListPosbillPasswords', 'pbpwStore', 'pbpwGetUploadFileTempCredential', 'pbpwGetCurrentAndNextHourPasswordsOfCustomDate'] })) {
						children.push({ name: localizationConstants.pbpw, url: '/tools/pbpw' });
					}

					// if (permissions.includes(allPermissionMapping['tseDeviceList'])) {
					// 	children.push({ name: localizationConstants.tseSerialUpload, url: '/tse/device/list' });
					// }
					// if (permissions.includes(allPermissionMapping['tseCustomerList'])) {
					// 	children.push({ name: localizationConstants.universeCustomers, url: '/tse/universe/customer/list' });
					// }
					// if (permissions.includes(allPermissionMapping['tseCustomerListV2'])) {
					// 	children.push({ name: localizationConstants.tseCustomers, url: '/tse/customer/list' });
					// }
					if (!children.length) {
						return;
					}
					break;
				case localizationConstants.offerTool:
					children = [];
					route = 'offer-tool';
					/* TODO: CHange the permission */
					if (permissions.includes(allPermissionMapping['offerToolListBundleGroups'])) {
						children.push({ name: localizationConstants.bundleGroup, url: '/offer-tool/bundleGroup' });
					}
					if (permissions.includes(allPermissionMapping['offerToolListBundles'])) {
						children.push({ name: localizationConstants.bundles, url: '/offer-tool/bundles' });
					}
					if (permissions.includes(allPermissionMapping['offerToolGetProducts'])) {
						children.push({ name: localizationConstants.products, url: '/offer-tool/products' });
					}
					if (!children.length) {
						return;
					}
					break;
				case localizationConstants.fiskaly:
					children = [];
					route = 'fiskaly';
					/* if (permissions.includes(allPermissionMapping['tseRegisterCreateOrUpdateTaxpayer'])) {
						children.push({ name: localizationConstants.taxpayerInformation, url: '/fiskaly/taxpayer' });
					}
					if (permissions.includes(allPermissionMapping['tseRegisterListEstablishments'])) {
						children.push({ name: localizationConstants.establishments, url: '/fiskaly/establishments' });
					}
					if (permissions.includes(allPermissionMapping['tseRegisterListTSS'])) {
						children.push({ name: localizationConstants.tss, url: '/fiskaly/tss' });
					} */
					if (permissions.includes(allPermissionMapping['tseRegisterListCustomers'])) {
						children.push({ name: localizationConstants.customer, url: '/fiskaly/customer' });
					}
					if (!children.length) {
						return;
					}
					break;
			}
			sidebarMenu.push({
				name: name,
				permissionName: sidebarItem,
				reactRoute: `/${route}`,
				permissions: allPermissions,
				children: children
			});
		});
		return sidebarMenu;
	}

	const getProfile = () => {
		dispatch(createAction(actionTypes.GET_PROFILE_INIT));
		httpService.get(API_CONFIG.path.profile).then((profileResponse: UserProfileResponse) => {
			profileResponse.sidebarMenu = getSideNavMenu(profileResponse);
			authService.setUserData(profileResponse);
			refreshLanguage(profileResponse.user.language || 'de');
			dispatch(createAction(actionTypes.GET_PROFILE_SUCCESS, profileResponse));
		}).catch(() => {
			dispatch(createAction(actionTypes.GET_PROFILE_FAIL));
		});
	}

	const getCustomerLicenseModules = () => {
		dispatch(createAction(actionTypes.GET_CUSTOMER_LICENSE_MODULE_INIT));
		httpService.get(API_CONFIG.path.customerLicenseModules).then((response: any) => {
			let modules: any = filter(response.items, item => item.status === 1);
			dispatch(createAction(actionTypes.GET_CUSTOMER_LICENSE_MODULE_SUCCESS, { modules }));
		}).catch(() => {
			dispatch(createAction(actionTypes.GET_CUSTOMER_LICENSE_MODULE_FAIL));
		});
	}

	useEffect(() => {
		getProfile();
		if (checkPermission({ permission: ['customerLicenseModuleList'] })) {
			getCustomerLicenseModules();
		}
	}, [])

	if (!auth.authData.auth) {
		return <Navigate to='/login' />
	}
	return (
		<nav className='navbar-default navbar-static-side' role='navigation'>
			<a className='navbar-back-btn' onClick={hideSidebar}><i className="fa fa-arrow-left" /></a>
			<ul className='nav' id='side-menu'>
				<li className='nav-header cursor-pointer' onClick={goToProfile}>
					<h3 className='profile-element'>
						POSBILL UNIVERSE
					</h3>
					<span className='user-role-element text-muted text-xs block'>
						{auth.userData.user.name}
					</span>
					<div className='logo-element'>PU</div>
				</li>
				{!!auth && !!auth.userData &&
					auth.userData.sidebarMenu.map(menu => (
						<NavItem
							isOpen={openStatus[menu.name] || false}
							toggleIsOpen={toggleIsOpen}
							hideSidebar={hideSidebar}
							key={menu.permissionName}
							renderSideNav={renderSideNav}
							menu={menu}
						/>
					))
				}
			</ul>

		</nav>
	);
}

export default SideNav;